<template>
  <b-modal
    id="modal-microsoft-import"
    ref="modal-microsoft-import"
    footer-class="flex-nowrap"
    size="lg"
    no-stacking
    no-close-on-backdrop
    no-close-on-esc
    centered
  >
    <template #modal-header>
      <h3>{{ $t('modals.createPersonalizationSet.microsoft.name') }}</h3>
    </template>

    <template #default>
      <div class="pt-0">
        <b-row>
          <b-col class="first-col border-right py-5" cols="4">
            <div class="pl-4">
              <h4 class="mb-4">Setup</h4>
              <ul>
                <li
                  class="step-1"
                  :class="{ 'past-step': (step > 1) }"
                  @click="setStep(1)"
                >
                  <b-icon
                    icon="circle-fill"
                    :class="{ active: (step === 1) }"
                    class="mr-2"
                    aria-hidden="true"
                    scale="1"
                  ></b-icon>
                  {{ $t('modals.createPersonalizationSet.microsoft.step1') }}
                </li>
                <li class="grey-line"></li>
                <li
                  class="step-2"
                  :class="{ 'past-step': (step > 2) }"
                  @click="setStep(2)"
                >
                  <b-icon
                    icon="circle-fill"
                    class="mr-2"
                    :class="{ active: (step === 2) }"
                    aria-hidden="true"
                    scale="1"
                  ></b-icon>
                  {{ $t('modals.createPersonalizationSet.microsoft.step2') }}
                </li>
                <li class="grey-line"></li>
                <li
                  class="step-3"
                  :class="{ 'past-step': (step > 3) }"
                  @click="setStep(3)"
                >
                  <b-icon
                    icon="circle-fill"
                    class="mr-2"
                    :class="{ active: (step === 3) }"
                    aria-hidden="true"
                    scale="1"
                  ></b-icon>
                  {{ $t('modals.createPersonalizationSet.microsoft.step3') }}
                </li>
                <li class="grey-line"></li>
                <li>
                  <b-icon
                    icon="circle-fill"
                    class="mr-2"
                    :class="{ active: (step === 4) }"
                    aria-hidden="true"
                    scale="1"
                  ></b-icon>
                  {{ $t('modals.createPersonalizationSet.microsoft.step4') }}
                </li>
              </ul>
            </div>
          </b-col>
          <b-col cols="8" class="py-5 pl-0">
            <div class="step-1 pl-4 pr-5" v-if="step === 1">
              <personalization-microsoft-step-one
                :is-reimport="isReimport"
                :microsoft-data="microsoftData"
                @next-step="goToStepTwo"
              />
            </div>
            <div class="step-2 pl-4 pr-5" v-if="step === 2">
              <personalization-microsoft-step-two
                :marketing-list-id-prop="microsoftData.marketingListId"
                @next-step="goToStepThree"
                @prev-step="goToPrev"
              />
            </div>
            <div class="step-3 pl-4 pr-5" v-if="step === 3">
              <personalization-microsoft-step-three
                :contact-fields-prop="microsoftData.contactFields"
                :marketing-list-id="microsoftData.marketingListId"
                @next-step="goToSummary"
                @prev-step="goToPrev"
              />
            </div>
            <div class="step-4 pl-4 pr-5" v-if="step === 4">
              <personalization-microsoft-summary
                :marketing-list-id-prop="microsoftData.marketingListId"
                :marketing-list-prop="microsoftData.marketingList"
                :contact-fields-prop="microsoftData.contactFields"
                @prev-step="goToPrev"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </template>

    <template #modal-footer>
      <b-button
        class="p-3 border-right-light-grey"
        block
        @click="cancelModal"
      >
        {{ $t('buttons.cancel') }}
      </b-button>
      <b-button
        :disabled="!enableOkButton"
        variant="primary"
        class="p-3"
        block
        @click="createPersonalizationSetMicrosoftImport"
      >
        {{ okButtonName }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import SetLoadingIndicator from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';

export default {
  name: 'PersonalizationMicrosoftModal',
  components: {
    PersonalizationMicrosoftStepOne: () => import('@/components/modals/personalization/microsoft/PersonalizationMicrosoftStep1.vue'),
    PersonalizationMicrosoftStepTwo: () => import('@/components/modals/personalization/microsoft/PersonalizationMicrosoftStep2.vue'),
    PersonalizationMicrosoftStepThree: () => import('@/components/modals/personalization/microsoft/PersonalizationMicrosoftStep3.vue'),
    PersonalizationMicrosoftSummary: () => import('@/components/modals/personalization/microsoft/PersonalizationMicrosoftSummary.vue'),
  },
  props: {
    reimportData: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    step: 1,
    microsoftData: {
      setName: '',
      setDescription: '',
      clientId: 'xxxxxxxxx',
      secret: 'xxxxxxxxx',
      authorityUrl: 'xxxxxxxxx',
      serverUrl: 'xxxxxxxxx',
      marketingListId: '',
      marketingList: [],
      contactFields: [],
    },
  }),
  computed: {
    isReimport() {
      return this.reimportData !== null;
    },
    enableOkButton() {
      if (this.step !== 4) {
        return false;
      }
      return true;
    },
    okButtonName() {
      if (this.step === 4) {
        return this.$t('modals.createPersonalizationSet.microsoft.importContacts');
      }
      return this.$t('modals.createPersonalizationSet.validate');
    },
  },
  mounted() {
    if (this.reimportData !== null) {
      this.microsoftData.setName = this.reimportData.name;
      this.microsoftData.setDescription = (this.reimportData.description)
        ? this.reimportData.description
        : '';
      this.microsoftData.contactFields = this.reimportData.schema.map((field) => {
        if (field.id) {
          return field.id;
        }
        return false;
      });
      this.$refs['modal-microsoft-import'].show();
    }
  },
  methods: {
    cancelModal() {
      this.$emit('close-modal-microsoft-import');
    },
    goToStepTwo(stepOneData) {
      this.microsoftData = {
        ...this.microsoftData,
        ...stepOneData,
      };
      this.step += 1;
    },
    goToStepThree(marketingListId, marketingList) {
      this.microsoftData = {
        ...this.microsoftData,
        marketingListId,
        marketingList,
      };
      this.step += 1;
    },
    goToSummary(contactFields, allCRMContactFields) {
      this.microsoftData = {
        ...this.microsoftData,
        contactFields,
        allCRMContactFields,
      };
      this.step += 1;
    },
    goToPrev() {
      this.step -= 1;
    },
    setStep(currentStep) {
      console.log('setStep', currentStep, this.step);
      if (currentStep < this.step) {
        this.step = currentStep;
      }
    },
    createPersonalizationSetMicrosoftImport() {
      this.$apollo.mutate({
        mutation: SetLoadingIndicator,
        variables: {
          isIndicatorLoading: true,
        },
      });
      this.$emit('create-personalization-set-microsoft-import', this.microsoftData);
    },
  },
};
</script>

<style scoped lang="scss">
.modal-body h4 {
  font-size: 15px;
  font-weight: bold;
  color: #44484C;
}

ul {
  padding-left: 0;
  list-style: none;

  li {
    color: #44484C;

    /deep/ svg {
      color: #B9B9B9;

      &.active {
        color: #53DC86;
      }
    }

    &.past-step {
      cursor: pointer;
    }
  }
}

div.step-1 {
  min-height: 120px;
}

.grey-line {
  height: 30px;
  border-left: 1px solid #B9B9B9;
  margin-left: 6px;
}

.show-url-icon svg {
  font-size: 60px;
}

.show-url {
  font-size: 1.1rem;
}

.loading-spinner {
  min-height: 400px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-spinner {
  position: absolute;
  inset: 0px;
  opacity: 0.85;
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100%;
  background-color: #f8f9fa;
}
</style>
